import { useState } from 'react'

function StartDateInput({ value, onchange }) {
  const thisYear = new Date().getFullYear()
  const minYear = 1976
  const years = Array(thisYear + 1 - minYear)
    .fill()
    .map((d, i) => i + 1976)
    .reverse()

  const [open, setOpen] = useState(false)
  const keyDown = newValue => e => {
    if (e.keyCode === 13) {
      setOpen(false)
      onchange(newValue)
    }
  }
  const valueChanged = newValue => e => {
    setOpen(false)
    onchange(newValue)
  }

  return (
    <>
      <label htmlFor='year-input' className='form-label'>
        Startår
      </label>
      <div className='year-dropdown'>
        <button className='dropbtn' onClick={() => setOpen(_ => !open)}>
          {value ? value : 'Vælg startår'}
          <div className={open ? 'arrow-icon open' : 'arrow-icon'}>
            <span className='left-bar'></span>
            <span className='right-bar'></span>
          </div>
        </button>
        <div className={open ? 'dropdown-content show' : 'dropdown-content'}>
          <div className='scroll-list'>
            {years.map(y => (
              <span
                tabIndex={open ? 0 : 1}
                onKeyDown={keyDown(y)}
                onClick={valueChanged(y)}
                key={'startyear-' + y}>
                {y}
              </span>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default StartDateInput
