import { Link } from 'react-router-dom'

function BackButton() {
  return (
    <div className='case-header'>
      <Link className='btn btn-link returnBtn' to='/'>
        &lt; Tilbage til søgningen
      </Link>
    </div>
  )
}

export default BackButton
