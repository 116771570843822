function CampaignCard({ id, title, text, url, urltitle, isFrontpage }) {
  return (
    <div className='result-target'>
      <div
        className={
          'result-card h-100 ' + (isFrontpage ? 'campaign' : 'campaign-subpage')
        }>
        <span className='date-placeholder'></span>
        <p className='h4'>{title}</p>
        <div
          className='card-message'
          dangerouslySetInnerHTML={{ __html: text }}></div>
        <a href={url}>{'Læs mere her'}</a>
      </div>
    </div>
  )
}
export default CampaignCard
