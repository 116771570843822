function Pagination({
  total = 0,
  size = 15,
  offset = 0,
  onPageChange,
  onSizeChange
}) {
  const numPages = Math.ceil(total / size)
  const current = offset / size
  let pages = Array.from(Array(numPages).keys())
  pages =
    current <= 5 ? pages.slice(0, 10) : pages.slice(current - 5, current + 5)

  const updateCurrent = pageNumber => e => {
    onPageChange(pageNumber * size)
  }

  const updateSize = size => e => {
    onSizeChange(size)
  }

  return (
    <>
      <div className='results-footer'>
        <div className='page-information row mt-5'>
          <div className='col-xs-12 col-md-6'>
            Viser {offset + 1}-{total < offset + size ? total : offset + size}{' '}
            ud af {total} resultater
          </div>
          <div className='col-xs-12 col-md-6 text-sm-start text-md-end'>
            Per side
            {[15, 30, 60, 120].map(s => (
              <button
                key={'perside-' + s}
                className={
                  s === size ? 'btn btn-link disabled' : 'btn btn-link'
                }
                onClick={updateSize(s)}>
                {s}
              </button>
            ))}
          </div>
        </div>

        <div className='paging'>
          <div
            key={'page-pevious'}
            className={current === 0 ? 'page-item disabled' : 'page-item'}>
            <button
              className='redbtn'
              onClick={updateCurrent(current - 1)}
              disabled={current === 0}>
              Forrige
            </button>
          </div>
          <div className='pages'>
            {pages.map(p => (
              <div
                key={'page-' + p}
                className={current === p ? 'page-item active' : 'page-item'}>
                <button
                  className=''
                  onClick={updateCurrent(p)}
                  disabled={current === p}>
                  {p + 1}
                </button>
              </div>
            ))}
          </div>
          <div
            key={'page-next' + (current + 1)}
            className={
              current + 1 === numPages ? 'page-item disabled' : 'page-item'
            }>
            <button
              className='redbtn'
              onClick={updateCurrent(current + 1)}
              disabled={current === numPages - 1}>
              Næste
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pagination
