import { useEffect } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'

import { buildQueryStringFromState } from '../utils'
import { useSearchQuery } from '../Api'
import { updateStateFromUrl } from './slice'

export const useSearch = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateStateFromUrl())
    // eslint-disable-next-line
  }, [dispatch, window.location.hash])

  const searchState = useSelector(state => state.search, shallowEqual)

  const query = buildQueryStringFromState(searchState)

  const { data: items, error, isFetching, isSuccess } = useSearchQuery(query)

  return { items, error, isFetching, isSuccess }
}
