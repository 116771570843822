import { useSelector, useDispatch } from 'react-redux'
import { updateCategories } from './slice'

import Categories from '../../components/Categories'
import SearchForm from './SearchForm'
import Page from '../Common/Page'

import { useSearch } from './hooks'

function SearchPage() {
  const { items, error, isFetching, isSuccess } = useSearch()
  const { categories } = useSelector(state => state.search)
  const dispatch = useDispatch()

  const categoryChanged = value => dispatch(updateCategories(value))

  const keywordsOptions =
    items &&
    items.aggregations &&
    items.aggregations.keyword_facets &&
    items.aggregations.keyword_facets.buckets
      ? items.aggregations.keyword_facets.buckets.map(o => ({
          value: o.key,
          label: `${o.key} (${o.doc_count})`
        }))
      : []
  const lawareasOptions =
    items &&
    items.aggregations &&
    items.aggregations.lawarea_facets &&
    items.aggregations.lawarea_facets.buckets
      ? items.aggregations.lawarea_facets.buckets.map(o => ({
          value: o.key,
          label: `${o.key} (${o.doc_count})`
        }))
      : []

  return (
    <>
      <Categories value={categories} onchange={categoryChanged} />

      <Page
        backButton={false}
        items={items}
        apiStatus={{
          error: error && error.error,
          loading: isFetching,
          completed: isSuccess
        }}>
        <SearchForm
          keywordsOptions={keywordsOptions}
          lawareasOptions={lawareasOptions}
        />
      </Page>
    </>
  )
}

export default SearchPage
