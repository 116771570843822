function Info({ message }) {
  return (
    <div className='alert-box' role='alert'>
      <div className='alert-background'>
        <h4 className='alert-heading'>Info !</h4>
        <p>{message}</p>
      </div>
    </div>
  )
}

export default Info
