import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  resetSearch,
  updateSearchableText,
  updateLawareas,
  updateKeywords,
  updateStartDate,
  updateEndDate,
  updateIsHistorical
} from './slice'
import { buildQueryStringFromState } from '../utils'

import KeywordsSelector from '../../components/KeywordsSelector'
import LawSelector from '../../components/LawSelector'
import SearchInput from '../../components/SearchInput'
import StartDateInput from '../../components/StartDateInput'
import EndDateInput from '../../components/EndDateInput'
import HistoricalStatusInput from '../../components/HistoricalStatusInput'
import FormActionButtons from '../../components/FormActionButtons'

function SearchForm({ keywordsOptions = [], lawareasOptions = [] }) {
  const searchState = useSelector(state => state.search)
  const [showAdvancedFitring, setShowAdvancedFitring] = useState(false)
  const [filteredKeywords, setFilteredKeywords] = useState(keywordsOptions)
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    setFilteredKeywords(keywordsOptions)
  }, [keywordsOptions])

  const toggleShowAdvanced = () => {
    setShowAdvancedFitring(() => !showAdvancedFitring)
  }

  const filterKeywordOptions = txt => {
    const result = !txt
      ? keywordsOptions
      : keywordsOptions.filter(
          o => o.value.toUpperCase().indexOf(txt.toUpperCase()) !== -1
        )
    setFilteredKeywords(result)
  }

  const textChanged = value => dispatch(updateSearchableText(value))
  const lawareasChanged = value => dispatch(updateLawareas(value))
  const keywordsChanged = value => dispatch(updateKeywords(value))
  const startDateChanged = value => dispatch(updateStartDate(value))
  const endDateChanged = value => dispatch(updateEndDate(value))
  const isHistoricalChanged = value => dispatch(updateIsHistorical(value))
  const resetForm = () => dispatch(resetSearch())

  const formSubmit = e => {
    e.preventDefault()
    const query = buildQueryStringFromState(searchState)
    history.push('/search/?' + query)
  }

  return (
    <>
      <div className='search-box mb-5'>
        <SearchInput value={searchState.q} onchange={textChanged} />

        <div className='custom-row'>
          <LawSelector
            value={searchState.lawareas}
            options={lawareasOptions}
            onchange={lawareasChanged}
          />
          <KeywordsSelector
            value={searchState.keywords}
            options={filteredKeywords}
            onchange={keywordsChanged}
            onsearch={filterKeywordOptions}
          />
        </div>
        <div className='search-advanced-container'>
          <div
            className={
              showAdvancedFitring
                ? 'row advanced-search-button isOpen'
                : 'row advanced-search-button'
            }>
            <div className='col'>
              <button
                type='button'
                className='btn btn-link'
                onClick={toggleShowAdvanced}>
                {showAdvancedFitring ? 'Luk' : 'Åben'} avanceret søgning
                <div
                  className={
                    showAdvancedFitring ? 'arrow-icon open' : 'arrow-icon'
                  }>
                  <span className='left-bar'></span>
                  <span className='right-bar'></span>
                </div>
              </button>
            </div>
          </div>
          <div
            className={
              showAdvancedFitring
                ? 'open advanced-accordion-outer'
                : 'advanced-accordion-outer'
            }>
            <div className='row advanced-accordion-inner'>
              <div className='col-12 mb-2'>
                <p className='h3'>År for udstedelse</p>
              </div>

              <div className='col-xs-12 col-md-6'>
                <div className='date-row'>
                  <div className='column-left'>
                    <StartDateInput
                      value={searchState['date.gte']}
                      onchange={startDateChanged}
                    />
                  </div>
                  <div className='column-right'>
                    <EndDateInput
                      value={searchState['date.lte']}
                      onchange={endDateChanged}
                    />
                  </div>
                </div>
              </div>
              <HistoricalStatusInput
                value={searchState.is_historical}
                onchange={isHistoricalChanged}
              />
            </div>
          </div>
          <FormActionButtons onreset={resetForm} onsubmit={formSubmit} />
        </div>
      </div>
    </>
  )
}

export default SearchForm
