function HistoricalStatusInput({ value, onchange }) {
  const statusChanged = index => e => {
    const newValue = value === true || value === false ? null : index === 0
    onchange(newValue)
  }

  return (
    <div className='col-xs-12 col-md-4 d-flex align-items-end'>
      <label className='radio-container'>
        <input
          name='historical-status'
          value={false}
          type='checkbox'
          autoComplete='off'
          className='form-check-input'
          checked={value === null || value === false}
          onChange={statusChanged(0)}
        />
        Gældende
      </label>

      <label className='radio-container' htmlFor='btnradio2'>
        <input
          name='historical-status'
          value={true}
          type='checkbox'
          autoComplete='off'
          className='form-check-input'
          checked={value === null || value === true}
          onChange={statusChanged(1)}
        />
        Historisk
      </label>
    </div>
  )
}

export default HistoricalStatusInput
