function SearchResultPlaceHolder() {
  return (
    <>
      <div className='row mb-5' id='sorter-row '>
        <div className='col-xs-12 col-md-9 placeholder-glow'>
          <span className='placeholder d-block w-50'></span>
          <span className='placeholder w-25'></span>
          <span className='placeholder w-25 mx-2'></span>
          <span className='placeholder w-25'></span>
        </div>
        <div className='col-xs-12 col-md-3 placeholder-glow'>
          <div className='sort-dropdown placeholder'></div>
        </div>
      </div>

      <div className='search-results'>
        <div className='row row-cols-1 row-cols-md-3 g-4'>
          <div
            className='result-card h-100 placeholder-glow'
            style={{ width: '32%' }}>
            <span className='text-placeholder placeholder w-25'></span>
            <div className='text-placeholder placeholder special-tag'></div>
            <p className='text-placeholder placeholder w-100'></p>
            <div className='card-tags'>
              <button className='text-placeholder placeholder tag'></button>
              <button className='text-placeholder placeholder tag'></button>
              <button className='text-placeholder placeholder tag'></button>
            </div>
            <div className='card-message'>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
            </div>
          </div>

          <div
            className='result-card h-100 placeholder-glow'
            style={{ width: '32%', marginLeft: '1em', marginRight: '1em' }}>
            <span className='text-placeholder placeholder w-25'></span>
            <div className='text-placeholder placeholder special-tag'></div>
            <p className='text-placeholder placeholder w-100'></p>
            <div className='card-tags'>
              <button className='text-placeholder placeholder tag'></button>
              <button className='text-placeholder placeholder tag'></button>
              <button className='text-placeholder placeholder tag'></button>
            </div>
            <div className='card-message'>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
            </div>
          </div>

          <div
            className='result-card h-100 placeholder-glow'
            style={{ width: '32%' }}>
            <span className='text-placeholder placeholder w-25'></span>
            <div className='text-placeholder placeholder special-tag'></div>
            <p className='text-placeholder placeholder w-100'></p>
            <div className='card-tags'>
              <button className='text-placeholder placeholder tag'></button>
              <button className='text-placeholder placeholder tag'></button>
              <button className='text-placeholder placeholder tag'></button>
            </div>
            <div className='card-message'>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
              <span className='text-placeholder placeholder w-100'></span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SearchResultPlaceHolder
