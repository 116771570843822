function FormActionButtons({ onreset, onsubmit }) {
  return (
    <div className='row button-row'>
      <div className='col'>
        <div className='float-end  m-4'>
          <button className='clearSearch btn btn-link me-2' onClick={onreset}>
            Ryd søgning
          </button>
          <button className='submitBtn btn' onClick={onsubmit}>
            &nbsp; &nbsp;Søg &nbsp; &nbsp;
          </button>
        </div>
      </div>
    </div>
  )
}

export default FormActionButtons
