import { createSlice } from '@reduxjs/toolkit'
import { getStateFromUrl } from '../utils'

const initialState = {
  q: '', // search text
  keywords: '',
  lawareas: '',
  categories: '',
  is_historical: null,
  sortby: '-published_date',
  'date.gte': '',
  'date.lte': '',
  size: 15,
  offset: 0
}

export const searchSlice = createSlice({
  name: 'search',
  initialState: initialState,
  reducers: {
    updateSearchableText: (state, action) => {
      state.q = action.payload
      state.sortby = ''
    },
    updateKeywords: (state, action) => {
      state.keywords = action.payload
      state.sortby = ''
    },
    updateLawareas: (state, action) => {
      state.lawareas = action.payload
      state.sortby = ''
    },
    updateCategories: (state, action) => {
      const txt = state.q
      Object.assign(state, initialState)
      state.categories = action.payload
      state.sortby = '-published_date'
      state.q = txt
    },
    updateIsHistorical: (state, action) => {
      state.is_historical = action.payload
      state.sortby = ''
    },
    updateSortBy: (state, action) => {
      state.sortby = action.payload
    },
    updateStartDate: (state, action) => {
      state['date.gte'] = action.payload
      state.sortby = ''
    },
    updateEndDate: (state, action) => {
      state['date.lte'] = action.payload
      state.sortby = ''
    },
    updateSize: (state, action) => {
      state.size = action.payload
    },
    updateOffset: (state, action) => {
      state.offset = action.payload
    },
    resetSearch: (state, action) => initialState,
    updateStateFromUrl: (state, action) => getStateFromUrl(state)
  }
})

export const {
  updateSearchableText,
  updateKeywords,
  updateLawareas,
  updateCategories,
  updateIsHistorical,
  updateSortBy,
  updateStartDate,
  updateEndDate,
  updateSize,
  updateOffset,
  resetSearch,
  updateStateFromUrl
} = searchSlice.actions

export default searchSlice.reducer
