import { Link } from 'react-router-dom'
import { useState } from 'react'

function Categories({ value, onchange }) {
  const categoryChanged = newValue => e => {
    setOpen(false)
    onchange(newValue)
  }

  const [open, setOpen] = useState(false)

  return (
    <div className='search-categories'>
      <button
        className='category-toggle dropbtn'
        onClick={() => setOpen(state => !state)}>
        {value || 'Alle kategorier'}
        <div className={open ? 'arrow-icon open' : 'arrow-icon'}>
          <span className='left-bar'></span>
          <span className='right-bar'></span>
        </div>
      </button>
      <nav className={open ? 'mynav open' : 'mynav'}>
        <ul>
          <li className={'category-link' + (value === '' ? ' active' : '')}>
            <Link to={'/'} title='Alle kategorier'>
              <button onClick={categoryChanged('')}>Alle kategorier</button>
            </Link>
          </li>
          <li
            className={
              'category-link' + (value === 'Arbejdsskade' ? ' active' : '')
            }>
            <Link to={'/search?categories=Arbejdsskade'} title='Arbejdsskade'>
              <button onClick={categoryChanged('Arbejdsskade')}>
                Arbejdsskade
              </button>
            </Link>
          </li>
          <li
            className={
              'category-link' +
              (value === 'Udbetaling Danmark' ? ' active' : '')
            }>
            <Link
              to={'/search?categories=Udbetaling Danmark'}
              title='Udbetaling Danmark'>
              <button onClick={categoryChanged('Udbetaling Danmark')}>
                Udbetaling Danmark
              </button>
            </Link>
          </li>
          <li
            className={
              'category-link' + (value === 'Kommunal' ? ' active' : '')
            }>
            <Link to={'/search?categories=Kommunal'} title='Kommunal'>
              <button onClick={categoryChanged('Kommunal')}>Kommunal </button>{' '}
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Categories
