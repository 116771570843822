import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import store from './store'
import { Provider } from 'react-redux'
import { HashRouter as Router } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.min.css'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

/** Make Plone title and desciption visible only on frontpage */
;(function ensurePloneHeaderOnlyOnFrontPage() {
  window.onhashchange = function () {
    const header = document.querySelector('article#content header')
    if (!header) {
      return
    }

    if (
      window.location.hash === '#/' ||
      window.location.hash.indexOf('#/search') === 0
    ) {
      header.style.display = 'block'
    } else {
      header.style.display = 'none'
    }
  }
})()
