import { Switch, Route } from 'react-router-dom'
import './App.scss'
import ArticlePage from './features/Article/ArticlePage'
import SearchPage from './features/Search/SearchPage'
import TaxonomyPage from './features/Taxonomy/TaxonomyPage'

function txPage(name) {
  return () => <TaxonomyPage taxonomyName={name} />
}

function App() {
  return (
    <div className='container'>
      <Switch>
        <Route path='/a/:articleId' component={ArticlePage} />
        <Route path='/keyword/:keywordId' render={txPage('keyword')} />
        <Route path='/lawarea/:lawareaId' render={txPage('lawarea')} />
        <Route path='/paragraph/:paragraphId' render={txPage('paragraph')} />
        <Route path='/' component={SearchPage} />
      </Switch>
    </div>
  )
}

export default App
