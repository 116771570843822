function InfoBox({ title, children }) {
  return (
    <div className='side-card'>
      {title.length > 1 && (
        <div>
          <p className='h3'>{title}</p>
        </div>
      )}

      {children}
    </div>
  )
}

export default InfoBox
