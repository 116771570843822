function SearchInput({ value, onchange }) {
  return (
    <div className='custom-row'>
      <div className='col'>
        <label htmlFor='search-text' className='form-label visually-hidden'>
          Søg
        </label>
        <input
          type='text'
          className='form-control'
          id='search-text'
          placeholder='Du kan foretage en fritekstsøgning, og du kan også søge på § eller PM-nummer'
          value={value}
          onChange={e => onchange(e.target.value)}
        />
        <div className='mobile-overlay'>
          <span>...</span>
        </div>
        <div className='search-icon'>
          <span className='search-circle'></span>
          <span className='search-handle'></span>
        </div>
      </div>
    </div>
  )
}

export default SearchInput
