function Error({ message }) {
  return (
    <div className='alert alert-danger' role='alert'>
      <h4 className='alert-heading'>Fejl !</h4>
      <p>{message}</p>
    </div>
  )
}

export default Error
