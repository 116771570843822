import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const decisionsApi = createApi({
  reducerPath: 'decisionsApi',
  baseQuery: fetchBaseQuery({ baseUrl: window.API_BASE_URL }),
  endpoints: builder => ({
    search: builder.query({
      query: qs => `/search?${qs}`,
      transformResponse: data => data
    }),
    article: builder.query({ query: articleId => `/rulings/${articleId}` })
  })
})

export const { useSearchQuery, useArticleQuery } = decisionsApi
