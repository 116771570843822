import { useState } from 'react'

function KeywordsSelector({ options, value, onchange, onsearch }) {
  const [open, setOpen] = useState(false)

  const keyDown = newValue => e => {
    if (e.keyCode === 13) {
      setOpen(false)
      onchange(newValue)
    }
  }

  const valueChanged = newValue => e => {
    setOpen(false)
    onchange(newValue)
  }

  return (
    <div className='search-filter-column'>
      <label>Emneord</label>
      <div className='subject-dropdown'>
        <button className='dropbtn' onClick={() => setOpen(_ => !open)}>
          {value || 'Vælg emneord'}
          <div className={open ? 'arrow-icon open' : 'arrow-icon'}>
            <span className='left-bar'></span>
            <span className='right-bar'></span>
          </div>
        </button>
        <div className={open ? 'dropdown-content show' : 'dropdown-content'}>
          <div className='wrap-input'>
            <input
              onChange={e => onsearch(e.target.value)}
              type='text'
              placeholder='Søg efter emne..'
              id='subjectInput'
            />
            <div className='search-icon'>
              <span className='search-circle'></span>
              <span className='search-handle'></span>
            </div>
          </div>

          <div className='scroll-list'>
            {options.map(o => (
              <span
                tabIndex={open ? 0 : 1}
                onKeyDown={keyDown(o.value)}
                onClick={valueChanged(o.value)}
                key={o.value}>
                {o.label}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default KeywordsSelector
