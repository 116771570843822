import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { buildQueryStringFromState } from '../utils'
import { useSearchQuery } from '../Api'
import { updateSortBy } from '../Search/slice'

export const useTaxonomyQuery = name => {
  const urlParamName = name + 'Id'
  const queryParamName = name + 's'

  const { [urlParamName]: paramValue = '' } = useParams()
  const { sortby, offset, size } = useSelector(state => state.search)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(updateSortBy(''))
  }, [paramValue, dispatch])

  const query = buildQueryStringFromState({
    [queryParamName]: paramValue,
    sortby,
    offset,
    size
  })

  const { data: items, error, isFetching, isSuccess } = useSearchQuery(query)

  return { items, error, isFetching, isSuccess, searchTerm: paramValue }
}
