import { useState } from 'react'

function LawSelector({ options, value, onchange }) {
  const [open, setOpen] = useState(false)

  const keyDown = newValue => e => {
    if (e.keyCode === 13) {
      setOpen(false)
      onchange(newValue)
    }
  }

  const valueChanged = newValue => e => {
    setOpen(false)
    onchange(newValue)
  }

  return (
    <div className='col-xs-12 col-md-6'>
      <label htmlFor='lov-omraader-input' className='form-label'>
        Lovområder
      </label>
      <div className='law-dropdown'>
        <button className='dropbtn' onClick={e => setOpen(o => !o)}>
          {value ? value : 'Vælg lovområde'}
          <div className={open ? 'arrow-icon open' : 'arrow-icon'}>
            <span className='left-bar'></span>
            <span className='right-bar'></span>
          </div>
        </button>
        <div className={open ? 'dropdown-content show' : 'dropdown-content'}>
          <div className='scroll-list'>
            {options.map(o => (
              <span
                tabIndex={open ? 0 : 1}
                onKeyDown={keyDown(o.value)}
                onClick={valueChanged(o.value)}
                key={o.value}>
                {o.label}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LawSelector
