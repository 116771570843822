import { useParams } from 'react-router-dom'

import { useArticleQuery } from '../Api'

export const useArticle = () => {
  const { articleId = '' } = useParams()
  const { data: article } = useArticleQuery(articleId)

  return article
}
