import { useSelector, useDispatch } from 'react-redux'

import SearchResultPlaceHolder from '../../components/SearchResultPlaceHolder'
import Error from '../../components/Error'
import Info from '../../components/Info'
import SearchResults from '../../components/SearchResults'
import Pagination from '../../components/Pagination'
import BackButton from '../../components/BackButton'
import SortBySelector from '../../components/SortBySelector'
import { updateSortBy, updateOffset, updateSize } from '../Search/slice'

function Page({
  items,
  searchTerm = '',
  backButton = true,
  apiStatus,
  children
}) {
  const searchState = useSelector(state => state.search)
  const dispatch = useDispatch()

  const sortByChanged = value => dispatch(updateSortBy(value))

  const offsetChanged = value => {
    document.getElementById('sorter-row').scrollIntoView()
    dispatch(updateOffset(value))
  }

  const sizeChanged = value => {
    dispatch(updateSize(value))
    offsetChanged(0)
  }

  const articles = items && items.hits && items.hits.hits ? items.hits.hits : []
  const total =
    items && items.hits && items.hits.total ? items.hits.total.value : 0

  return (
    <>
      {backButton && (
        <div className='row mb-3'>
          <div className='col-xs-12 col-md-8 mb-1 mt-3'>
            <BackButton />
          </div>
        </div>
      )}

      {children}

      {apiStatus.loading && <SearchResultPlaceHolder />}
      {apiStatus.error && <Error message={apiStatus.error} />}
      {apiStatus.completed && articles.length === 0 && (
        <Info message={'Din søgning gav ingen resultater.'} />
      )}

      {apiStatus.completed && articles.length > 0 && (
        <>
          <div className='row mb-5' id='sorter-row'>
            <div className='col-xs-12 col-md-9'>
              <p className='h3'>
                {searchTerm
                  ? `Din søgning på "${searchTerm}" gav ${total} resultater`
                  : 'Søgeresultater'}
              </p>
              Viser {searchState.offset + 1}-
              {total < searchState.offset + searchState.size
                ? total
                : searchState.offset + searchState.size}{' '}
              ud af {total} resultater
            </div>
            <SortBySelector
              value={searchState.sortby}
              onchange={sortByChanged}
            />
          </div>

          <SearchResults
            isFrontpage={!backButton}
            items={articles.map(item => ({
              ...item._source,
              id: item._id
            }))}
          />

          <Pagination
            total={total}
            size={searchState.size}
            offset={searchState.offset}
            onPageChange={offsetChanged}
            onSizeChange={sizeChanged}
          />
        </>
      )}
    </>
  )
}

export default Page
