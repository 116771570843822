export const buildQueryStringFromState = state => {
  const params = Object.keys(state)
    .filter(k => state[k] !== '' && state[k] !== null && k)
    .map(k => `${k}=${state[k]}`)
  return params.join('&')
}

function getUrlParameter(name) {
  name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]')
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  var results = regex.exec(window.location.hash)
  return results === null
    ? ''
    : decodeURIComponent(results[1].replace(/\+/g, ' '))
}

export function getStateFromUrl(state) {
  let newState = { ...state }
  Object.keys(newState).forEach(k => {
    let value = getUrlParameter(k)
    switch (k) {
      case 'size':
      case 'offset':
        value = value ? parseInt(value) : state[k]
        break
      case 'is_historical':
        value = value ? value === 'true' : state[k]
        break
      default:
        value = value ? value : state[k]
        break
    }
    newState[k] = value
  })

  return newState
}

export function toLocalDate(date) {
  return new Date(date).toLocaleDateString('da-DK', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  })
}
