import Page from '../Common/Page'
import { useTaxonomyQuery } from './hooks'

function TaxonomyPage({ taxonomyName }) {
  const { items, error, isFetching, isSuccess, searchTerm } =
    useTaxonomyQuery(taxonomyName)

  return (
    <Page
      items={items}
      searchTerm={searchTerm}
      apiStatus={{
        error: error && error.error,
        loading: isFetching,
        completed: isSuccess
      }}
    />
  )
}

export default TaxonomyPage
