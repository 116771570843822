import { Link, useParams, useHistory } from 'react-router-dom'
import { toLocalDate } from '../features/utils'

function Card({ id, date, title, tags, text, historical }) {
  const { keywordId } = useParams()
  const history = useHistory()

  const gotoArticle = e => {
    e.target.tagName !== 'BUTTON' && history.push('/a/' + id)
  }

  return (
    <div className='result-target' onClick={gotoArticle}>
      <div className='result-card h-100'>
        <span>{toLocalDate(date)}</span>
        {historical && <div className='special-tag'>Historisk</div>}
        <p className='h4'><Link to={'/a/' + id} className="card-title">{title}</Link></p>
        <div className='card-tags'>
          {tags &&
            tags.map((tag, idx) => (
              <Link to={'/keyword/' + encodeURI(tag)} key={id + '-' + tag}>
                <button
                  className={'tag' + (tag === keywordId ? ' active' : '')}>
                  {tag}
                </button>
              </Link>
            ))}
        </div>
        <div className='card-message'>{text}</div>
      </div>
    </div>
  )
}
export default Card
