import { Link } from 'react-router-dom'
import InfoBox from './InfoBox'
import RelatedInfo from './RelatedInfo'
import Card from './Card'
import BackButton from './BackButton'
import { toLocalDate } from '../features/utils'

function Article({ article, onAccordionClick }) {
  return (
    <>
      <div className='row mb-5 case-section' onClick={e => onAccordionClick(e)}>
        <div className='col-xs-12 col-md-8 mb-1 mt-3'>
          <div className='header-container'>
            {article.is_historical && (
              <div className='historical-label'>Historisk</div>
            )}
            <BackButton />
          </div>
          <h1>{article.title}</h1>
          <div className='case-abstract'>
            <div dangerouslySetInnerHTML={{ __html: article.abstract }}></div>
          </div>
          <div className='case-body'>
            <div className='see-all-accordions'>
              <button className='see-all-accordions-button'>
                Se hele principmeddelelsen
              </button>
            </div>
            <div dangerouslySetInnerHTML={{ __html: article.html_body }}></div>
          </div>
        </div>
        <div className='col-xs-12 col-md-4 mt-3 mb-5'>
          <InfoBox title=' '>
            {article.issue_year && (
              <div>
                <p className='h3'>År for udstedelse</p>
                <span>{article.issue_year}</span>
              </div>
            )}
            {article.signature_date && (
              <div>
                <p className='h3'>Dato for underskrift</p>
                <span>{toLocalDate(article.signature_date)}</span>
              </div>
            )}
            {article.published_date && (
              <div>
                <p className='h3'>Offentliggørelsesdato</p>
                <span>{toLocalDate(article.published_date)}</span>
              </div>
            )}
            <div className='status'>
              <span>Status: </span>
              <span
                className={
                  'dot ' + (article.is_historical ? 'red' : 'green')
                }></span>
              <span>{article.is_historical ? 'Historisk' : 'Gældende'}</span>
            </div>
            {article.related_info && (
              <RelatedInfo content={article.related_info} />
            )}
            {article.current_ruling && (
              <Link to={'/a/' + article.current_ruling.id}>
                Link til gældende afgørelse
              </Link>
            )}
          </InfoBox>
          <InfoBox title='Paragraf'>
            <div className='paragraph'>
              {article.paragraphs.map(paragraph => (
                <Link
                  to={'/paragraph/' + paragraph}
                  key={'paragraph-' + paragraph}
                  title={paragraph}>
                  <button key={'par-' + paragraph}>{paragraph}</button>
                </Link>
              ))}
            </div>
          </InfoBox>
          <InfoBox title='Lovområder'>
            <div className='tags'>
              {article.lawareas.map(lawarea => (
                <Link
                  to={'/lawarea/' + lawarea}
                  key={'law-' + lawarea}
                  title={lawarea}>
                  <button>{lawarea}</button>
                </Link>
              ))}
            </div>
          </InfoBox>
          <InfoBox title='Emner'>
            <div className='tags'>
              {article.keywords.map(keyword => (
                <Link
                  to={'/keyword/' + encodeURI(keyword)}
                  key={'emne-' + keyword}
                  title={keyword}>
                  <button>{keyword}</button>
                </Link>
              ))}
            </div>
          </InfoBox>
          <div className='m-3'>Journalnummer {article.journal_number}</div>
        </div>
      </div>
      <div className='row row-cols-1 mb-5 related-cases-title'>
        <div>
          <h2>3 relaterede principmeddelelser</h2>
        </div>
      </div>
      <div className='search-results'>
        <div className='row row-cols-1 row-cols-md-3 g-4 related-cases'>
          {article.related.map(item => (
            <Card
              key={item.id}
              id={item.id}
              date={item.published_date}
              title={item.title}
              tags={item.keywords}
              text={item.short_description}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default Article
