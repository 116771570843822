import { useState } from 'react'

function RelatedInfo({ content }) {
  const [show, setShow] = useState(false)

  return (
    <div className='relatedinfo-wrapper'>
      <a href='/' className='relatedinfo-link' onMouseOver={e => setShow(true)}>
        Relateret info
      </a>
      <div
        className='relatedinfo-box'
        style={{ display: show ? 'block' : 'none' }}
        onMouseLeave={e => setShow(false)}>
        <p className='h4 mb-4'>Relateret info</p>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </div>
  )
}

export default RelatedInfo
