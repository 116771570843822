import Card from './Card'
import CampaignCard from './CampaignCard'

function SearchResults({ items, isFrontpage }) {
  return (
    <>
      <div className={'search-results'}>
        <div className='row row-cols-1 row-cols-md-3 g-4'>
          {items.map(item => {
            return item.contenttype === 'campaignbox' ? (
              <CampaignCard
                id={item.contenttype}
                key={item.contenttype}
                title={item.title}
                url={item.linkurl}
                urltitle={item.linktitle}
                text={item.short_description}
                isFrontpage={isFrontpage}
              />
            ) : (
              <Card
                key={item.id || item.contenttype}
                id={item.id || item.contenttype}
                date={item.published_date}
                title={item.title}
                tags={item.keywords}
                text={item.short_description}
                historical={item.is_historical}
              />
            )
          })}
        </div>
      </div>
    </>
  )
}

export default SearchResults
