import { configureStore } from '@reduxjs/toolkit'
import { decisionsApi } from './features/Api'
import { searchSlice } from './features/Search/slice'

export default configureStore({
  reducer: {
    search: searchSlice.reducer,
    [decisionsApi.reducerPath]: decisionsApi.reducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(decisionsApi.middleware)
})
