import { useEffect } from 'react'
import Article from '../../components/Article'
import { useArticle } from './hooks'

function ArticlePage() {
  const article = useArticle()

  const newArticle = article && article.id

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [newArticle])

  if (!article) {
    return null
  }

  const accordionClicked = e => {
    const el = e.target
    const isAccordion = el.parentNode.classList.contains('accordion')
    const isOpenAll = el.classList.contains('see-all-accordions-button')

    isAccordion && el.parentNode.classList.toggle('open')

    if (isOpenAll) {
      el.classList.toggle('openall')
      const openall = el.classList.contains('openall')
      document
        .querySelectorAll('.case-body section.accordion button')
        .forEach(b => {
          b.parentNode.classList.remove('open')
          openall && b.parentNode.classList.add('open')
        })

      el.innerText = openall
        ? 'Luk principmeddelelsen'
        : 'Se hele principmeddelelsen'
    }
  }

  return <Article article={article} onAccordionClick={accordionClicked} />
}

export default ArticlePage
