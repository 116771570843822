import { useState } from 'react'

const sortOptions = {
  '': 'Relevans',
  '-published_date': 'Nyeste til ældste',
  published_date: 'Ældste til nyeste'
}

function SortBySelector({ value, onchange }) {
  const [open, setOpen] = useState(false)

  const keyDown = newValue => e => {
    if (e.keyCode === 13) {
      setOpen(false)
      onchange(newValue)
    }
  }

  const sortByChanged = newValue => e => {
    setOpen(false)
    onchange(newValue)
  }

  return (
    <div className='col-xs-12 col-md-3'>
      <label htmlFor='sorton-input' className='form-label'>
        Sorter efter
      </label>

      <div className='sort-dropdown'>
        <button
          className='dropbtn sortselectbtn'
          onClick={() => setOpen(state => !state)}>
          {sortOptions[value] || 'Relevans'}
          <div className={open ? 'arrow-icon open' : 'arrow-icon'}>
            <span className='left-bar'></span>
            <span className='right-bar'></span>
          </div>
        </button>
        <div className={open ? 'dropdown-content show' : 'dropdown-content'}>
          <div className='scroll-list'>
            {Object.keys(sortOptions).map(k => (
              <span
                tabIndex={open ? 0 : 1}
                onKeyDown={keyDown(k)}
                onClick={sortByChanged(k)}
                key={'sortby-' + k}>
                {sortOptions[k]}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SortBySelector
